.simulator {
    position: relative;
}

.simulator h1 {
    font-size: 1em;
    background-color: #eee;
    padding: 10px;
}

.simulator_list {
    background-color: #fff !important;
}

.simulator_list .title {
    font-size: 0.995em;
    background-color: #7366FF;
    padding: 15px;
    color: #fff;
    text-transform: uppercase;
}

.simulator_list:nth-of-type(2n+0) {
    background-color: #000;
}

.emprestimos {
    background-color: #eee;
    padding: 20px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 5px;
}

.emprestimos table{
    margin-bottom: 10px;
}