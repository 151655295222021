.list_beneficio {
  margin-bottom: 25px;
}

.list_beneficio h5 {
  margin-bottom: 20px;
  font-size: 0.875em;
}

.list_beneficio .value_beneficio {
  font-weight: bold;
  font-size: 1.3em;
}

.list_ben_title {
  font-size: 0.875em;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.ficha {
  background: #fff;
  padding: 12px;
  border-radius: 5px;
  margin: 0.50%;
  flex-basis: 100%;
}

.title_ficha {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
}

.title_ficha h1 {
  font-size: 0.975em;
}

.ficha_item {
  border-bottom: 1px solid #eee;
  padding: 8px 0;
}

.ficha_item:last-child {
  border-bottom: none !important;
  padding-bottom: 0;
}

.ficha_item_title span {
  font-size: 0.875em;
  font-weight: bold;
}

.ficha_item_desc span {
  font-size: 0.875em;
}

.ficha_item_desc .icon_phone {
  margin-left: 8px;
}

.margem_title {
  padding: 3%;
  text-align: center;
}

.margem_title_renda {
  padding: 3%;
  text-align: center;
}

.margem_title_renda h1 {
  font-size: 0.875em;
  margin-bottom: 15px;
  font-weight: bold;
}

.margem_title_renda span {
  font-size: 1.6em;
  font-weight: bold;
}

.margem_title h1 {
  font-size: 0.775em;
  font-weight: bold;
}

.margem_title span {
  font-size: 1.3em;
  font-weight: bold;
}

.margem_title input {
  border: none;
  width: 30%;
  text-align: center;
  border-bottom: 1px solid #eee;
  margin-top: 8px;
}

.limite_liberado {
  margin-top: 5px;
  text-align: center;
}

.update_off_button {
  font-size: 0.875em;
}

@media (max-width: 1400px) {
  .margem_title input {
    width: 100%;
  }

  .update_off_button {
    font-size: 0.675em;
  }
}

@media (max-width: 1080px) {
  .margem_title input {
    width: 100%;
  }

  .update_off_button {
    font-size: 0.575em;
  }
}

.list_function_mailing a {
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 1.1em;
  cursor: pointer;
}

.list_function_mailing a:hover {
  background-color: #eee;
  cursor: pointer;
}

.list_function_mailing i {
  margin-right: 8px;
}

.alert_required {
  border: 1px solid red;
  /* Define a cor e a largura da borda */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.spinner-container {
  padding: 150px;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.value_aproximado {
  font-size: 0.675em;
}

.card-header {
  padding: 15px 25px !important;
}

.card-header h5 {
  font-size: 1.1em !important;
  text-transform: uppercase !important;
}

.card-header {
  font-size: 0.875em !important;
}